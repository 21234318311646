<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-center borderColor borderColorBackground mb-3 p-3"
    >
      <div class="mr-3">
        選擇{{ timeToText }}份 :&nbsp;
        <date-picker
          v-model="datepickerDate"
          :type="selectedTime"
          placeholder="請選擇"
          valueType="format"
          :format="timeFormat"
          style="width: 120px"
          :disabled-date="disabledDateAfterToday"
        />
      </div>
      <button
        class="datepickerButton"
        :class="{ 'date-active': selectedTime === 'month' }"
        @click="handleSelectTime('month')"
      >
        {{ $t("dataAnalysis.month") }}
      </button>
      <button
        class="datepickerButton mr-3"
        :class="{ 'date-active': selectedTime === 'year' }"
        @click="handleSelectTime('year')"
      >
        {{ $t("dataAnalysis.year") }}
      </button>
      <button class="confirmButton m-5px" @click="getSearch(selectedTime)">
        {{ $t("deviceStatus.confirm") }}
      </button>
    </div>
    <div class="otherTableStyle borderColor my-3 d-flex">
      <div style="width: 20%;">
        <table class="w-100 h-100 text-left dataTable">
          <tr>
            <td>本{{ searchedTime }}累計日照量 (kWh/m²)</td>
            <td>{{ dbDataHandle(sumTableObj.accIRR) }}</td>
          </tr>
          <tr>
            <td>本{{ searchedTime }}累計峰瓩值 (kWh/ kWp)</td>
            <td>{{ dbDataHandle(sumTableObj.peakWatt) }}</td>
          </tr>
          <tr>
            <td>本{{ searchedTime }}實際發電量 (kWh)</td>
            <td>{{ dbDataHandle(sumTableObj.accKwh) }}</td>
          </tr>
          <tr>
            <td>本{{ searchedTime }}平均發電效率 (%)</td>
            <td>{{ dbDataHandle(sumTableObj.accPR.all * 100) }}</td>
          </tr>
        </table>
      </div>
      <div style="width: 80%;">
        <button
          class="confirmButton m-5px d-block ml-auto"
          style="width: 120px"
          @click="downloadData"
        >
          資料下載
        </button>
        <highcharts ref="chart" :options="monthsChart" />
      </div>
    </div>
    <div class="otherTableStyle borderColor mb-4">
      <table class="w-100 text-center dataTable">
        <tr>
          <th class="px-2 py-1">時間</th>
          <th class="px-2 py-1">發電量 (kWh)</th>
          <th class="px-2 py-1">峰瓩值 (kWh/kWp)</th>
          <th class="px-2 py-1">日照量 (kWh/m²)</th>
          <th class="px-2 py-1">發電效率 (%)</th>
          <th class="px-2 py-1">事件備註</th>
        </tr>
        <tr v-for="(i, idx) in tableData" :key="idx">
          <td>{{ i.date }}</td>
          <td>
            {{ !dbDataHandle(i.accKwh) ? "-" : dbDataHandle(i.accKwh) }}
          </td>
          <td>
            {{ !dbDataHandle(i.peakWatt) ? "-" : dbDataHandle(i.peakWatt) }}
          </td>
          <td>
            {{ !dbDataHandle(i.accIRR) ? "-" : dbDataHandle(i.accIRR) }}
          </td>
          <td>
            {{
              !dbDataHandle(i.accPR * 100) ? "-" : dbDataHandle(i.accPR * 100)
            }}
          </td>
          <td>-</td>
        </tr>
        <tr>
          <td>累積</td>
          <td>{{ tableAccObj.kWh }}</td>
          <td>{{ tableAccObj.peakWatt }}</td>
          <td>{{ tableAccObj.IRR }}</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>平均</td>
          <td>{{ tableAvgObj.kWh }}</td>
          <td>{{ tableAvgObj.peakWatt }}</td>
          <td>{{ tableAvgObj.IRR }}</td>
          <td>{{ tableAvgObj.PR }}</td>
          <td>-</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon'
import downloadCsv from 'download-csv'
import {
  getMonSumTableApi,
  getDaysTableApi,
  getDaysChartApi,
  getYearSumTableApi,
  getMonthsTableApi,
  getMonthsChartApi
} from '../api'
import { dbDataHandle } from '../store/function/common/index'
import { disabledDateAfterToday } from '@/utils/datetime'
export default {
  name: 'MonthlyReport',
  data () {
    return {
      tableAccObj: {
        kWh: 0,
        peakWatt: 0,
        IRR: 0,
        PR: 0
      },
      tableAvgObj: {
        kWh: 0,
        peakWatt: 0,
        IRR: 0,
        PR: 0
      },
      sumTableObj: {
        accIRR: '-',
        peakWatt: '-',
        accKwh: '-',
        accPR: {
          all: '-'
        }
      },
      tableData: [],
      chartData: [],
      datepickerDate: DateTime.local().minus({ months: 1 }).toFormat('yyyy-MM'),
      selectedTime: 'month',
      searchedTime: '月',
      monthsChart: {
        chart: {
          height: 350,
          events: {
            load () {
              this.showLoading()
            }
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: []
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false
        },
        yAxis: [
          {
            title: {
              text: '發電量 (kWh)'
            }
          },
          {
            title: {
              text: '日照量 (kWh/m²)'
            },
            opposite: true
          },
          {
            title: {
              text: '發電效率 (%)'
            },
            opposite: true,
            min: 0
          }
        ],
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        labels: {},
        tooltip: {
          shared: true
        },
        series: [
          {
            type: 'column',
            name: '發電量 (kWh)',
            data: [],
            yAxis: 0
          },
          {
            type: 'line',
            name: '日照量 (kWh/m²)',
            data: [],
            yAxis: 1
          },
          {
            type: 'line',
            name: '發電效率 (%)',
            data: [],
            yAxis: 2
          }
        ]
      }
    }
  },
  computed: {
    timeFormat () {
      return this.selectedTime === 'month' ? 'YYYY-MM' : 'YYYY'
    },
    timeToText () {
      return this.selectedTime === 'month' ? '月' : '年'
    }
  },
  methods: {
    disabledDateAfterToday,
    dbDataHandle,
    handleSelectTime (time) {
      this.selectedTime = time
      this.datepickerDate =
        time === 'month'
          ? DateTime.local().minus({ months: 1 }).toFormat('yyyy-MM')
          : DateTime.local().minus({ year: 1 }).toFormat('yyyy')
    },
    async getSearch (time) {
      if (time === 'month') {
        await Promise.all([
          this.getMonthSumData(),
          this.getDaysTableData(),
          this.getDaysChartData()
        ])
        this.searchedTime = '月'
      } else {
        await Promise.all([
          this.getYearSumData(),
          this.getMonthsTableData(),
          this.getMonthsChartData()
        ])
        this.searchedTime = '年'
      }
    },
    async getMonthSumData () {
      const monSumTable = await getMonSumTableApi(
        this.$route.query.fid,
        this.datepickerDate
      )
      if (monSumTable?.data?.success) {
        this.sumTableObj = monSumTable.data.data
      }
    },
    async getDaysTableData () {
      const tableData = await getDaysTableApi(
        this.$route.query.fid,
        this.datepickerDate
      )
      if (tableData?.data?.success) {
        this.tableData = tableData.data.data
        this.calculateTableData()
      }
    },
    async getDaysChartData () {
      const dayschart = await getDaysChartApi(
        this.$route.query.fid,
        this.datepickerDate
      )
      if (dayschart?.data?.success) {
        this.chartData = dayschart.data.data
        this.drawChart()
      }
    },
    async getYearSumData () {
      const yearSumTable = await getYearSumTableApi(
        this.$route.query.fid,
        this.datepickerDate
      )
      if (yearSumTable?.data?.success) {
        this.sumTableObj = yearSumTable.data.data
      }
    },
    async getMonthsTableData () {
      const monthsTable = await getMonthsTableApi(
        this.$route.query.fid,
        this.datepickerDate
      )
      if (monthsTable?.data?.success) {
        this.tableData = monthsTable.data.data
        this.calculateTableData()
      }
    },
    async getMonthsChartData () {
      const monthsChart = await getMonthsChartApi(
        this.$route.query.fid,
        this.datepickerDate
      )
      if (monthsChart?.data?.success) {
        this.chartData = monthsChart.data.data
        this.drawChart()
      }
    },
    calculateTableData () {
      this.tableAccObj = {
        kWh: 0,
        peakWatt: 0,
        IRR: 0,
        PR: 0
      }
      this.tableAvgObj = {
        kWh: 0,
        peakWatt: 0,
        IRR: 0,
        PR: 0
      }
      this.tableData.forEach((x, idx) => {
        if (x.accKwh) {
          this.tableAccObj.kWh = dbDataHandle(this.tableAccObj.kWh + x.accKwh)
        }
        if (x.peakWatt) {
          this.tableAccObj.peakWatt = dbDataHandle(
            this.tableAccObj.peakWatt + x.peakWatt
          )
        }
        if (x.accIRR) {
          this.tableAccObj.IRR = dbDataHandle(this.tableAccObj.IRR + x.accIRR)
        }
        if (x.accPR) {
          this.tableAccObj.PR = dbDataHandle(this.tableAccObj.PR + x.accPR)
        }
        if (idx === this.tableData.length - 1) {
          this.tableAvgObj.kWh = dbDataHandle(
            this.tableAccObj.kWh / this.tableData.length
          )
          this.tableAvgObj.peakWatt = dbDataHandle(
            this.tableAccObj.peakWatt / this.tableData.length
          )
          this.tableAvgObj.IRR = dbDataHandle(
            this.tableAccObj.IRR / this.tableData.length
          )
          this.tableAvgObj.PR = dbDataHandle(
            (this.tableAccObj.PR / this.tableData.length) * 100
          )
        }
      })
    },
    downloadData () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.monthsChart.xAxis.categories)
      this.monthsChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.monthsChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.chartData.forEach((obj) => {
        download[0].data.push(obj.accKwh)
        download[1].data.push(obj.accIRR)
        download[2].data.push(obj.accPR * 100)
      })

      this.monthsChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })

      const fileName = `案場報表_${this.datepickerDate}`

      downloadCsv(downloadArr, fields, fileName)
    },
    drawChart () {
      this.monthsChart.xAxis.categories = []
      this.monthsChart.series.forEach((x) => {
        x.data = []
      })
      this.chartData.forEach((x) => {
        this.monthsChart.xAxis.categories.push(x.date)
        this.monthsChart.series[0].data.push(dbDataHandle(x.accKwh))
        this.monthsChart.series[1].data.push(dbDataHandle(x.accIRR))
        this.monthsChart.series[2].data.push(dbDataHandle(x.accPR * 100))
      })
      this.$nextTick(function () {
        this.$refs.chart.chart.hideLoading()
      })
    }
  },
  watch: {},
  async created () {},
  async mounted () {
    this.getSearch('month')
  }
}
</script>

<style lang="scss" scoped>
.dataTable {
  td {
    padding: 5px 10px;
    font-weight: 700;
    border: solid 1px rgb(236, 236, 236);
  }
}
</style>
